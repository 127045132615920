.gates {
  display: grid;
  grid-template: 1fr / repeat(8, 1fr);
  gap: 0;
  overflow: hidden;
  position: absolute;
  z-index: var(--z-index-gates);
  inset: 60% auto auto -300%;
  min-width: 400%;
  will-change: transform;

  &__item {
    position: relative;
    width: 100%;
    aspect-ratio: 20 / 12;

    @include min(phone-portrait) {
      aspect-ratio: 20 / 10;
    }

    @include min(phone-landscape) {
      aspect-ratio: 20 / 9;
    }

    @include min(tablet-portrait) {
      aspect-ratio: 20 / 8;
    }
  }
}

.gate {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  inset: 0;
  margin: auto;
  aspect-ratio: 580 / 392;
  height: 100%;

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &.protected {
    &::after {
      background-image: url("../images/gate-protected.png");
    }
  }

  &.unprotected {
    &::after {
      background-image: url("../images/gate-unprotected.png");
    }
  }
}

.gate-collision {
  position: relative;
  z-index: 2;
  width: 92%;
  height: 100%;
  margin: auto;
}
