.ball {
  pointer-events: none;
  position: relative;
  z-index: 1;
  transform: translateY(0);
  will-change: transform;
  width: clamp(70px, 9vmin, 110px);
  height: clamp(70px, 9vmin, 110px);
  border-radius: 50%;

  &::before {
    position: absolute;
    inset: 0;
    margin: auto;
    display: block;
    content: '';
    background-image: url("../images/ball.png");
    background-size: cover;
    background-position: center;
  }
}
