.cherobora-image {
  position: absolute;
  z-index: var(--z-index-cherobora-image);
  inset: 14% 18% auto auto;
  width: max(90px, 14vmin);
  height: max(90px, 14vmin);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
