.impact-marker {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 99;
  animation: opacity-in 1500ms 500ms ease forwards 1;

  @keyframes opacity-in {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  .svg-icon--impact-marker {
    width: 100%;
    height: 100%;
    fill: crimson;
  }
}
