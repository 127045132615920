.image-container {
  width: 100%;
  height: 100%;

  picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &--page-background-top {
    position: absolute;
    inset: 0 0 auto;
    margin: auto;
    width: min(100vw, 900px);
    height: 71%;

    &::before {
      position: absolute;
      inset: auto 0 0;
      display: block;
      content: '';
      width: 100%;
      height: 40%;
      background: linear-gradient(to top, var(--c-sapphire) 3%, transparent);
    }

    img {
      object-position: center bottom;
    }
  }

  &--page-background-bottom {
    position: absolute;
    inset: 0 0 auto;
    margin: auto;
    width: 100%;
    height: 100%;

    &::before,
    &::after {
      content: "";
      z-index: 3;
      width: 16vmin;
      height: 100%;
      display: block;
      position: absolute;
    }

    &::before {
      background: linear-gradient(to right, var(--c-sapphire) 3%, transparent);
      inset: 0 auto 0 0;
    }

    &::after {
      background: linear-gradient(to left, var(--c-sapphire) 3%, transparent);
      inset: 0 0 0 auto;
    }

    img {
      object-position: center top;
    }
  }
}
