.page {
  overflow-x: hidden;
  position: relative;
  max-width: 900px;
  height: 100dvh;
  margin: auto;
  display: grid;
  grid-template: 3.5fr 1fr 1fr / 1fr;
  gap: 0;

  &__game {
    position: relative;
    min-height: 400px;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 16vmin;
      height: 100%;
      z-index: 3;
    }

    &::before {
      inset: 0 auto 0 0;
      background: linear-gradient(to right, var(--c-sapphire) 3%, transparent);

      @include max(phone-landscape) {
        transform: rotate(-3deg) translateX(-2vmin);
      }
    }

    &::after {
      inset: 0 0 0 auto;
      background: linear-gradient(to left, var(--c-sapphire) 3%, transparent);

      @include max(phone-landscape) {
        transform: rotate(3deg) translateX(2vmin);
      }
    }
  }

  &__control {
    position: relative;
    z-index: var(--z-index-page-control);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__bottom {
    overflow: hidden;
    position: relative;
  }
}
