html,
body {
  position: relative;
}

body {
  overflow: hidden auto;
  width: 100vw;
  min-height: 100dvh;
  background-color: var(--c-sapphire);
}
