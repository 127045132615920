.dialog-content {
//.dialog-content:not(.dialog-content--auth) {
  display: none;
  gap: min(3.5vmin, 35px) 0;
  width: min(calc(100vw - 40px), 520px);
  min-height: 100px;
  padding: 4vmin 4.5vmin;
  background-color: var(--c-blue-screen);
  box-shadow: 0 0 0 3px inset var(--c-pale-blue);
  border-radius: 21.5px;
  justify-items: center;
  text-align: center;

  .display-large {
    margin: 10px 0 0;
    text-transform: uppercase;
    font-size: 9.6em;
    font-weight: 700;
    line-height: 1;
    color: var(--c-yellow);
  }

  .body-large {
    margin: 0;
    font-size: 3.2em;
    font-weight: 700;
    line-height: 1;
    color: var(--c-white);

    span {
      color: var(--c-yellow);
    }
  }

  .button-orange {
    margin-top: 10px;
  }
}

.dialog-content--auth {
  display: none;
  gap: 0;
  width: calc(100vw - 40px);
}

.dialog-lite {
  &--start {
    .dialog-content {
      &--start {
        display: grid;
      }

      &--win {
        display: none;
      }

      &--bonus-sport {
        display: none;
      }

      &--bonus-casino {
        display: none;
      }
    }
  }

  &--win {
    .dialog-content {
      &--start {
        display: none;
      }

      &--win {
        display: grid;
      }

      &--bonus-sport {
        display: none;
      }

      &--bonus-casino {
        display: none;
      }
    }
  }

  &--bonus-sport {
    .dialog-content {
      &--start {
        display: none;
      }

      &--win {
        display: none;
      }

      &--bonus-sport {
        display: grid;
      }

      &--bonus-casino {
        display: none;
      }
    }
  }

  &--bonus-casino {
    .dialog-content {
      &--start {
        display: none;
      }

      &--win {
        display: none;
      }

      &--bonus-sport {
        display: none;
      }

      &--bonus-casino {
        display: grid;
      }
    }
  }
}

.dialog-lite--start {
  &__container {
    &-inner {
      &::before {
        position: absolute;
        z-index: 2;
        inset: calc((max(80px, 12vmin) - 20px) * -1) 0 auto 0;
        margin: auto;
        content: '';
        background-image: url(../images/cherobolla.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: max(80px, 12vmin);
        height: max(80px, 12vmin);
      }
    }
  }
}
