.indicator {
  position: absolute;
  inset: auto auto 0 50%;
  transform: translateX(-50%) translateY(48%);
  margin: auto;
  width: clamp(160px, 25vmin, 200px);
  height: clamp(160px, 25vmin, 200px);
  perspective: clamp(160px, 25vmin, 200px);

  &__animation {
    transform: rotateX(70deg);

    &-pancake {
      width: clamp(160px, 25vmin, 200px);
      height: clamp(160px, 25vmin, 200px);
      border-radius: 50%;
      animation: pulse 1.5s infinite;
      animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
      will-change: transform;

      body.lucky-hit-ball &,
      body.failure-hit-ball &,
      body.is-game-won & {
        animation-play-state: paused;
      }

      @keyframes pulse {
        0%, 100% {
          transform: scale(1);
          box-shadow: 0 0 0 2.5px inset red;
          background-color: rgb(255 0 0 / 25%);
        }

        54% {
          box-shadow: 0 0 0 2.5px inset red;
          background-color: rgb(255 0 0 / 25%);
        }

        60% {
          box-shadow: 0 0 0 4px inset limegreen;
          background-color: rgb(50 205 50 / 25%);
        }

        96% {
          transform: scale(0.3);
          box-shadow: 0 0 0 5px inset limegreen;
          background-color: rgb(50 205 50 / 25%);
        }
      }
    }
  }

  body.is-game-won & {
    display: none;
  }
}
