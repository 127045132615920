.button-hidden {
  cursor: pointer;
  position: absolute;
  inset: 0;
  margin: auto;
  z-index: var(--z-index-button-hidden);
  width: 100%;
  height: 100%;
}

.button-orange {
  overflow: hidden;
  position: relative;
  margin: min(80px, 8vmax) auto min(20px, 2vmax);
  padding: 0 42px;
  font-size: 17px;
  border: none;
  display: grid;
  place-content: center;
  height: 46px;
  border-radius: 46px;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
  background: linear-gradient(0.25turn, #85FF67, #FBFF22);
  color: var(--c-black);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  body.lucky-hit-ball &,
  body.failure-hit-ball & {
    pointer-events: none;
  }

  > * {
    position: relative;
    z-index: 2;
    opacity: 1;
    transition: opacity 250ms ease;

    body.failure-hit-ball & {
      opacity: 0.5;
    }
  }

  &::before {
    display: block;
    content: '';
    position: absolute;
    inset: 0 auto 0 0;
    z-index: 1;
    background-color: crimson;

    body.failure-hit-ball & {
      animation: scale-up 2000ms ease-out 1;
    }

    @keyframes scale-up {
      0%, 100% {
        width: 0;
      }

      98.5% {
        width: 100%;
      }

      99% {
        width: 0;
      }
    }
  }
}
