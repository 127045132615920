.logotype-punch {
  position: absolute;
  z-index: var(--z-index-logotype-punch);
  inset: var(--v-offset-sm) 0 auto;
  margin: auto;
  display: flex;
  align-items: center;

  .svg-icon--logotype-punch {
    margin: auto;
    width: min(20vmin, 200px);
    aspect-ratio: 268 / 54;
    fill: var(--c-white);
  }
}
